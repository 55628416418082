import styled from 'styled-components';

function Notifications() {
    return (
        <Container>
            Here Will be the Notifications
        </Container>
    );
}

const Container = styled.div`
    text-align: center;
    width: 100%;
    min-height: 85vh;
`;

export default Notifications;
